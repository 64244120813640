import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IParticipantSectionsContext } from './ParticipantSectionsContext';
import flatten from 'lodash/flatten';
import { getSectionNavigationInfo, loadParticipantSections } from './helpers';
import {
  getStepNavigationInfo,
  loadParticipantSteps,
  updateParticipantStepStatus,
} from '../ParticipantStepsDataProvider/helpers';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import {
  getSectionNumberInProgram,
  isSelfPaced,
} from '../../selectors/isSelfPaced';
import memoize from 'lodash/memoize';
import { getChallengeData } from '../storage-contexts/Challenge';
import {
  addOnLoginHandler,
  IOnLoginHandlerPriority,
} from '../GeneralDataProvider/helpers/onLogin';
import { isV3enabled } from '../../experiments/isV3enabled';
import { getParticipantSectionsV3 } from './GetParticipantSectionsV3';
import { requestParticipantStep } from './requestParticipantStep';
import { requestParticipantSection } from './requestParticipantSection';
import { ParticipantStep } from '../../types/v3Types';

const updateParticipantsSections = async (
  flowAPI: ControllerFlowAPI,
  program: Challenge,
) => {
  const _sections = await loadParticipantSections(flowAPI, program);
  const _steps = flatten<ParticipantStep>(
    _sections.map((section) => section.steps || []),
  );

  flowAPI.controllerConfig.setProps({
    listParticipantSections: _sections,
    participantSteps: { steps: _steps },
  });
};

const handleSectionsAfterLogin = () => {
  addOnLoginHandler({
    priority: IOnLoginHandlerPriority.SECONDARY,
    handler: async (flowAPI: ControllerFlowAPI) => {
      const challengeData = await getChallengeData(flowAPI);
      if (isV3enabled(flowAPI)) {
        const { sections, steps } = await getParticipantSectionsV3(
          challengeData?.challenge,
          flowAPI,
        );
        return flowAPI.controllerConfig.setProps({
          listParticipantSections: sections,
          participantSteps: { steps },
          isListParticipantSectionsRequestInProgress: false,
        });
      }
      if (isSelfPaced(challengeData?.challenge)) {
        await updateParticipantsSections(flowAPI, challengeData?.challenge);
      } else {
        flowAPI.controllerConfig.setProps({
          participantSteps: await loadParticipantSteps(
            flowAPI,
            challengeData?.challenge,
          ),
        });
      }
    },
  });
};

export const participantSectionsPropsMap = memoize(async function (
  flowAPI: ControllerFlowAPI,
): Promise<IParticipantSectionsContext> {
  const challengeData = await getChallengeData(flowAPI);
  const challenge = challengeData?.challenge;
  const useSections =
    isSelfPaced(challenge) && Boolean(getSectionNumberInProgram(challenge));
  let sections: IParticipantSectionsContext['listParticipantSections'];
  let steps: IParticipantSectionsContext['participantSteps']['steps'];
  if (isV3enabled(flowAPI)) {
    ({ sections, steps } = await getParticipantSectionsV3(challenge, flowAPI));
    if (!isSelfPaced(challenge)) {
      sections = [];
    }
  } else {
    sections = useSections
      ? await loadParticipantSections(flowAPI, challenge)
      : [];
    steps = useSections
      ? flatten<ParticipantStep>(sections.map((section) => section.steps || []))
      : (await loadParticipantSteps(flowAPI, challenge)).steps;
  }
  const selectedSection = await getSectionNavigationInfo(flowAPI, sections);
  const { selectedStep } = await getStepNavigationInfo(flowAPI, steps);

  void handleSectionsAfterLogin();

  return {
    selectedStep,
    selectedSection,
    participantSteps: { steps },
    listParticipantSections: sections,
    isListParticipantSectionsRequestInProgress: false,
    isParticipantStepsLoading: false,
    updateParticipantSections: () =>
      updateParticipantsSections(flowAPI, challenge),
    updateParticipantSteps: async () => {
      flowAPI.controllerConfig.setProps({
        participantSteps: await loadParticipantSteps(flowAPI, challenge),
      });
    },
    updateParticipantStepStatus: async (payload) =>
      updateParticipantStepStatus(flowAPI, payload),
    requestParticipantSection: async (sectionId, sections) =>
      requestParticipantSection(sectionId, sections, flowAPI),
    requestParticipantStep: async (stepId, steps) =>
      requestParticipantStep(stepId, steps, flowAPI),
    generateStreamUrl: async (programId, stepId) => {},
  };
});
